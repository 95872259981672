@import url("https://fonts.googleapis.com/css2?family=Manrope&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

html {
  font-size: 16px;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  line-height: 1;
}

.app {
  --primary-color: #118f84;
  --secondary-color: #6c757d;
  --text-color: #202224;
  --bg-color: #f8f7ef;
  --white: #fff;
  --gray: #98a3a9;
  --whiteSmoke: #f5f5f5;
  --borderGray: #dbdce0;
  --uploadBlue: #4f7fae;
  --black: #000;
  --deleteRed: #c95858;
  --shuttleGray: #606367;
  --lightYellow: #f2efde;
  --success-green: #4abe51;
  --failure-red: #c57979;
  --bg: #fff;
  --bg-lighter: #fff;
  --linkBlue: rgb(35, 102, 202);
  --mm-link: #0000ee;
  --bg-white: #fff;
  --bg-gray: #dbdce0;
  --bg-white-light: #fff;
  --bg-districtIncome: transparent;
  --total: #606367;
  --ser-bg: #f8f7ef;
  --ser-gray: #f5f5f5;
  --plan-color: #197ca4;
  --population-bg: #fff;
  --population-count: rgb(129, 134, 137);
  --odd-row: #f5f3e7;
  --dark-border: #868686;
  --budget-odd-row: #f2efde80;
  --icon-fill: #118f84;
  --population-dynamic-bg: #f5f5f5;
  --population-dynamic-border: #dbdce0;
}

.app.dark {
  --bg: linear-gradient(180deg, #002d5e 0%, #01112d 100%);
  --bg-lighter: rgb(25, 57, 94);
  --text-color: #fff;
  --shuttleGray: #fff;
  --linkBlue: #ffc803;
  --primary-color: #ffc803;
  --whiteSmoke: rgb(25, 57, 94);
  --bg-white: rgb(25, 57, 94);
  --bg-gray: rgb(45, 82, 122);
  --bg-white-light: rgb(66, 99, 135);
  --lightYellow: rgb(66, 99, 135);
  --bg-districtIncome: rgb(46, 76, 112);
  --bg-color: rgb(25, 57, 94);
  --borderGray: rgb(45, 82, 122);
  --total: #82cdff;
  --ser-bg: rgb(66, 99, 135);
  --ser-gray: rgb(66, 99, 135);
  --plan-color: #fff;
  --population-bg: rgba(255, 255, 255, 0.1);
  --population-count: #ffc803;
  --odd-row: rgb(66, 99, 135);
  --budget-odd-row: rgb(66, 99, 135);
  --mm-link: #fff;
  --icon-fill: #fff;
  --population-dynamic-bg: rgba(245, 245, 245, 0.1);
  --population-dynamic-border: #fff;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid blue;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.esri-view-surface.esri-view-surface--touch-none:focus {
  outline: none;

  &::after {
    display: none;
  }
}

.mb-4 {
  margin-bottom: 4rem;
}

textarea {
  resize: none;
}

.image-grid-general {
  display: flex;
  gap: 0.625rem;
  flex-wrap: wrap;

  img {
    width: 12rem;
    height: 8.1875rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    background: var(--whiteSmoke);
  }
}

.custom-label {
  -webkit-text-stroke: 0.125px white;
  text-stroke: 0.125px white;
  font-weight: 800;
  z-index: 102;
}

* {
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
    background: rgba(206, 206, 206, 0.5);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #98a3a9;
    border-radius: 5px;
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }
}

.gmnoprint {
  display: none;
}

@media (min-width: 1800px) {
  html {
    font-size: 18px;
  }
}

#pac-input {
  display: none;
}
